@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url(/fonts/vendor/typeface-ibm-plex-sans/files/ibm-plex-sans-latin-100.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url(/fonts/vendor/typeface-ibm-plex-sans/files/ibm-plex-sans-latin-200.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(/fonts/vendor/typeface-ibm-plex-sans/files/ibm-plex-sans-latin-300.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(/fonts/vendor/typeface-ibm-plex-sans/files/ibm-plex-sans-latin-400.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(/fonts/vendor/typeface-ibm-plex-sans/files/ibm-plex-sans-latin-500.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(/fonts/vendor/typeface-ibm-plex-sans/files/ibm-plex-sans-latin-600.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(/fonts/vendor/typeface-ibm-plex-sans/files/ibm-plex-sans-latin-700.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* -------------------------------------------------------------------------------
    Variables
   ------------------------------------------------------------------------------- */

$shadow = 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);

$padding = 2rem;
$padding-sm = 1rem;

$transition = 0.35s ease all;

// Colours
$black = #061a29;
$white = #fff;
$primary = #24AAE1;
$highlight = #8d09d2;
$dark = #061a29;
$light = #24AAE1;
$lightgrey = #ccc;
$verylightgrey = #eee;
$error = #ef443a;
$success = #059669;
$warning = #FBBF24;



/* -------------------------------------------------------------------------------
    HTML Elements
   ------------------------------------------------------------------------------- */

html {
	height: 100%;
}

body {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    font-family: 'IBM Plex Sans', sans-serif;
    color: $black;
    background: #eee;

    h1, .h1,
    h2, .h2,
    h3, .h3,
    h4, .h4,
    h5, .h5,
    h6, .h6,
    blockquote,
    th {
        font-weight: 500;
        margin-bottom: 1.0rem;
    }

    h1, .h1 {
        font-size: 2rem;
        margin-bottom: 1.5rem;

        .cl-primary {
            transition: $transition;
        }
    }

    h2, .h2 {
        font-size: 1.8rem;
        margin-bottom: 1.2rem;
    }

    h3, .h3 {
        font-size: 1.6rem;
    }

    h4, .h4 {
        font-size: 1.4rem;
    }

    h5, .h5 {
        font-size: 1.3rem;
    }

    h6, .h6 {
        font-size: 1.2rem;
    }

    p {
        font-size: 1rem;
        margin-bottom: 1rem;
    }
}

#checkin {
    &.tablet-checkin {
        * {
            user-select: none;
        }
    }
    

    p {
        overflow-wrap: break-word;
    }
}



:focus, :visited, :hover, :active,
a:focus, a:visited, a:hover, a:active {
    outline: 0 !important;
    text-decoration: none;
}



/* -------------------------------------------------------------------------------
    Custom Helpers
   ------------------------------------------------------------------------------- */

.bg {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

.min-h-screen {
    min-height: calc(100vh - 4rem - 31px);
}

.scroll-me {
    position: relative;
    border: 1px solid #eee;
    
    > div {
        padding: 10px;
        height: calc(100vh - 17rem);
        min-height: 300px;
        overflow-y: scroll;
    }
    
    &:before {
        position: absolute;
        width: 100%;
        bottom: 0;
        height: 70px;
        background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);;
        content: '';
        backface-visibility: hidden;
    }
}

.btn {
    font-size: 1rem;
    padding: 0.75rem 1.5rem;
    border-radius: 0.25rem;
    color: $white;
    font-weight: 500;
    white-space: nowrap;
    background: $primary;
    transition: $transition;
    position: relative;
    border: 2px solid #eee;

    &.btn-sm {
        font-size: 0.85rem;
        padding: 0.5rem 1rem;
    }

    &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        border-width: 8px;
        border-color: #eee #eee transparent transparent;
        background: rgba($dark, 0.3);
        -moz-border-radius: 0 0 0 3px;
        border-radius: 0 0 0 3px;
        display: block;
        width: 0;
        transition: $transition;
        backface-visibility: hidden;
    }

    &:hover {
        background: rgba($primary, 0.8)

        &:before {
            border-width: 0;
        }
    }

    &.btn-primary {
        background: $primary;

        &:hover {
            background: rgba($primary, 0.8)
        }
    }

    &.btn-highlight {
        background: $highlight;

        &:hover {
            background: rgba($highlight, 0.8)
        }
    }

    &.btn-dark {
        background: $dark;

        &:hover {
            background: rgba($dark, 0.8)
        }
    }

    &.btn-light {
        background: $light;

        &:hover {
            background: rgba($light, 0.8)
        }
    }

    &.btn-error {
        background: $error;

        &:hover {
            background: rgba($error, 0.8)
        }
    }

    &.btn-warning {
        background: $warning;

        &:hover {
            background: rgba($warning, 0.8)
        }
    }

    &.btn-success {
        background: $success;

        &:hover {
            background: rgba($success, 0.8)
        }
    }
}

.form-box {
    .btn {
        border: 2px solid #fff;

        &:before {
            border-color: #fff #fff transparent transparent;
        }
    }
}

.auth-page {
    .max-w-md {
        max-width: 24rem;
    }

    .box.form-box {
        padding: 2.4rem 1.8rem 2.4rem 1.8rem;
    }
}

.bg-white       { background: $white; }
.bg-black       { background: $black; }
.bg-primary     { background: $primary; color: $white; }
.bg-highlight   { background: $highlight; }
.bg-dark        { background: $dark; color: $white; }
.bg-light       { background: $light; }
.bg-lightgrey   { background: $lightgrey; }
.bg-verylightgrey{ background: $verylightgrey; }
.bg-error       { background: $error; }
.bg-success     { background: $success; }

.cl-white       { color: $white; }
.cl-black       { color: $black; }
.cl-primary     { color: $primary; }
.cl-highlight   { color: $highlight; }
.cl-dark        { color: $dark; }
.cl-light       { color: $light; }
.cl-lightgrey   { color: $lightgrey; }
.cl-error       { color: $error; }
.cl-success     { color: $success; }

.m-0            { margin: 0 !important; }
.mt-0           { margin-top: 0 !important; }
.mr-0           { margin-right: 0 !important; }
.mb-0           { margin-bottom: 0 !important; }
.ml-0           { margin-left: 0 !important; }
.mx-0           { margin-left: 0 !important; margin-right: 0 !important; }
.my-0           { margin-top: 0 !important; margin-bottom: 0 !important; }

.p-0            { padding: 0 !important; }
.pt-0           { padding-top: 0 !important; }
.pr-0           { padding-right: 0 !important; }
.pb-0           { padding-bottom: 0 !important; }
.pl-0           { padding-left: 0 !important; }
.px-0           { padding-left: 0 !important; padding-right: 0 !important; }
.py-0           { padding-top: 0 !important; padding-bottom: 0 !important; }

.shadow {
	box-shadow: $shadow !important;
}

.box {
    background: $white;
    color: $black;
    border-radius: 5px;
    box-shadow: $shadow;
    padding: 1.5rem;

    &.form-box {
        padding: $padding 1.5rem;
    }
}

.hidden {
    display: none;
}

@media only screen and (max-width : 767px) {
    .hidden-mobi {
        display: none;
    }
}

@media only screen and (min-width : 768px) {
    .hidden-desktop {
        display: none;
    }
}

.overlay {
    display: block;
    position: fixed;
    z-index: 10;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0,0,0, 0.35);
    backdrop-filter: blur(2px);
    backface-visibility: hidden;
}

#checkin {
    .modal {
        top: 5vh;
        max-height: 90vh;
        height: auto;
        transform: translateX(-50%);
    }
}

.modal-wrapper {
    .overlay {
        transition: 0.3s ease opacity;

        &.hidden {
            opacity: 0;
            pointer-events: none;
        }
    }

    .modal {
        z-index: 12;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 700px;
        background: #fff;
        box-shadow: $shadow;
        padding: 1.5rem;
        border-radius: 5px;
        opacity: 0;
        pointer-events: none;
        overflow-y: scroll;
        max-height: 90vh;
        
        &.show {
            opacity: 1;
            pointer-events: inherit;
        }
        
        .close-icon {
            position: absolute;
            top: 1rem;
            right: 1rem;
            cursor: pointer;
        }
    }
}

.w-auto {
	width: auto !important;
}

.chart-container {
    height: 350px;

    &.chart-higher {
        height: 500px;
    }
}



/* -------------------------------------------------------------------------------
    Navigation
   ------------------------------------------------------------------------------- */

.logo {
    h3 {
        margin: 0;
    }
}

#headers {
    #left, #subnav {
        padding: $padding-sm $padding;
    }

    #subnav {
        background-color: #fff !important;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 1px 2px 0 rgba(0, 0, 0, 0.03);
    }
}

#content {
    padding: $padding $padding*2;
}

#nav {
    padding-top: $padding;
    padding-bottom: $padding;
    
    hr {
        opacity: 0.2;
    }

    .nav-item {
        padding: 0.5rem $padding;
        transition: $transition;
        font-weight: 400;

        &:hover {
            background: rgba($black, 0.1);
        }

        &.active {
            background: rgba($black, 0.2);
        }

        .nav-icon {
            width: 1.6rem !important;
            transform: translate(-5px);
        }
    }
}

#nav-mobile {
    > nav {
    }

    .nav-item {
        padding: 0.4rem $padding;
        transition: $transition;

        &.active {
            background: rgba($primary, 1);
            color: $white;
        }

        .nav-icon {
            width: 1.6rem !important;
            transform: translate(-5px);
        }
    }
}

.dropdown {
    a {
        &:hover {
            background: $primary;
            color: $white;
        }
    }
}


/* -------------------------------------------------------------------------------
    Tables
   ------------------------------------------------------------------------------- */

table.table {
	$table_pad = 1.5rem;

    th {
        text-align: left;
        padding: 0.5rem 0.2rem 0.5rem $table_pad;
        border-bottom: 1px solid rgba($black, 0.1);
        font-size: 1rem;
    }

    .td-pad {
        padding: 0.3rem 0.2rem 0.3rem $table_pad;
    }

    td {
        border-bottom: 1px solid rgba($black, 0.05);
        font-size: 0.9rem;

        a, span {
            padding: 0.3rem 0.2rem 0.3rem $table_pad;
        }
    }

    tbody {
        tr {
            transition: $transition;

            &:hover {
                background: rgba($black, 0.05);
            }
        }
    }

    a.table-icon {
        width: 2rem;
        display: inline-block;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        opacity: 0.3;
        transition: $transition;

        .nav-icon {
            pointer-events: none;
        }

        &:hover {
            opacity: 1;
            color: $primary;
        }
    }

    a.table-icon:last-child {
        margin-right: 1rem;
    }
}



/* -------------------------------------------------------------------------------
    Tables
   ------------------------------------------------------------------------------- */

.pagination {
    a, > div {
        padding: 0.2rem 0.4rem;
        margin: 0 0.2rem;
        min-width: 2rem;
        text-align: center;
        transition: $transition;

        &.active {
            background: $primary !important;
            color: $white;
        }
    }

    a {
        background: $white;
        box-shadow: $shadow;

        &:hover {
            background: rgba($black, 0.05);
        }
    }
}



/* -------------------------------------------------------------------------------
    Forms
   ------------------------------------------------------------------------------- */

.toggle-checkbox:checked {
    right: 0;
    border: 1px solid $lightgrey;
    border-color: #68D391;
}
.toggle-checkbox:checked + .toggle-label {
    background: $primary;
    background-color: #68D391;
}

.form-el {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-bottom: 1rem;

    .form-label {
        margin-bottom: 0.3rem;
        display: block;
        color: $black;
        font-weight: 500;
    }

    > div {
        display: block !important;
        position: relative;
        z-index: 2;

        &.inline {
            display: inline !important;
        }

        &.form-error {
            z-index: 1;
        }

        > div {
            div.autocomplete-item {
                padding: 0.2rem 0.5rem;
            }
        }
    }

    .form-textarea,
    .form-input,
    .form-select,
    .form-file,
    input[aria-autocomplete="list"] {
        outline: 0;
        border: 1px solid $lightgrey;
        box-shadow: none !important;
        color: $dark;
        padding: 0.5rem;
        line-height: 1.5;
        display: block;
        width: 100%;
        border-radius: 0.25rem;
        appearance: none;
        position: relative;

        &.PhoneInput {
            display: flex !important;
        }

        &[readonly] {
            color: #888;

            &:focus {
                border: 1px solid $lightgrey;
            }
        }

        &:focus {
            border: 1px solid $light;
        }
    }

    .form-file {
        max-width: 300px;
    }

    .form-textarea.error,
	.form-input.error,
	.form-textarea.error,
	.form-select.error {
		border-color: $error;
	}

    .form-editor {}

	.form-error {
		margin-top: 0.2rem;
		color: $error;
	}

    .form-radio,
    .form-check {
        position: relative;
        margin-left: 3px;
        cursor: pointer;
        opacity: 0;
    }

    .form-radio + label,
    .form-check + label{
        $label_offset = 20px;
        position: relative;
        margin-left: -($label_offset);
        padding-left: 25px;
        padding-right: 10px;
        cursor: pointer;
        transition: $transition;
        color: $dark;

        span {
            width: 20px;
            height: 20px;
            display: block;
            border: 2px solid $primary;
            border-radius: 50%;
            position: absolute;
            top: 0;
            left: calc(-0.5rem + 4px);
            cursor: pointer;
            transition: $transition;

            &:after {
                content: '';
                $dot_size = 6px;
                width: $dot_size;
                height: $dot_size;
                margin-top: -($dot_size/2);
                margin-left: -($dot_size/2);
                position: absolute;
                top: 50%;
                left: 50%;
                border-radius: 50%;
                background: #fff;
                display: block;
                transition: $transition;
            }
        }
    }

    .form-radio:checked + label,
    .form-radio:active + label,
    .form-check:checked + label,
    .form-check:active + label {
        opacity: 1;

        span {
            opacity: 1;

            &:after {
                background: $primary;
                opacity: 1;
            }
        }
    }

    .form-check + label {
        span {
            border-radius: 0 !important;

            &:after {
                $dot_size = 10px;
                width: $dot_size;
                height: $dot_size;
                margin-top: -($dot_size/2);
                margin-left: -($dot_size/2);
                border-radius: 0 !important;
            }
        }
    }

    .form-check:checked + label,
    .form-check:active + label {
        opacity: 1;

        span {
            opacity: 1;

            &:after {
                background: url('../img/check.svg') no-repeat center center;
                background-size: cover;
                opacity: 1;
            }
        }
    }

    .form-radio:hover + label,
    .form-radio:focus + label,
    .form-radio:active + label,
    .form-check:hover + label,
    .form-check:focus + label,
    .form-check:active + label {
        span {
            border-color: darken($primary, 15);

            &:after {
                opacity: 1;
            }
        }
    }

    .caption-group-wrapper {
        white-space: nowrap;
    }
}

form {
    .btn {
        margin: 0.5rem 0.5rem 0 0.5rem;
    }
}

#flash-messages {
    margin-bottom: 0rem;
}

.auth-page {
    #flash-messages {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
}

::-webkit-input-placeholder    { color: $lightgrey !important; }
:-moz-placeholder              { color: $lightgrey !important; }
::-moz-placeholder             { color: $lightgrey !important; }
:-ms-input-placeholder         { color: $lightgrey !important; }
.placeholder                   { color: $lightgrey !important; }



/* -------------------------------------------------------------------------------
    Component Specific
   ------------------------------------------------------------------------------- */

#location-settings {
    section.location-section {
        &.active {
            .location-section-head {
                h2 {
                    background: rgba($dark, 0.2) !important;
                }
            }

            .location-section-body {
                display: block;
            }
        }

        .location-section-head {
            background: $primary;

            h2 {
                cursor: pointer;
                color: #fff;
                padding: 0.5rem 1.5rem;
                margin-bottom: 0;
                font-weight: 400;
                border-bottom: 1px solid rgba($dark, 0.1);
                transition: $transition;

                .num {
                    font-weight: 200;
                }

                &:hover {
                    background: rgba($black, 0.1);
                }
            }
        }

        .location-section-body {
            padding: $padding 1.5rem;
            display: none;
        }
    }
}

/* Home Screen */

.home-screen {
    .btn {
        display: block;
        text-align: center;
        margin-top: 1rem;
    }
}

/* Checkin */

#checkin {
    .btn {
        border: none;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        min-width: 100px;

        &:before {
            display: none;
        }
    }

    div.flex.items-center.justify-between {
        > button {
            &:first-child {
                margin-left: 0.5rem;
            }
            &:last-child {
                margin-right: 0.5rem;
            }
        }
    }

    > div {
        > .box {
            width: 100%;
            max-width: 720px;
            
            &.screen-legal,
            &.screen-privacy,
            &.screen-key_terms {
                min-height: calc(100vh - 3rem);
            }

            > div,
            > form > div {
                > h2 {
                    margin-left: -1.5rem;
                    margin-right: -1.5rem;
                    padding-top: 0.5rem;
                    padding-bottom: 0.5rem;

                    &:first-child {
                        margin-top: -1.5rem;
                        padding-top: 1.5rem;
                        padding-bottom: 0.5rem;
                    }
                }

                > h2 + div {
                    margin-top: 1.5rem;
                }
            }
        }
    }

    .connection-status {
        position: fixed;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        background: #fff;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        padding: 5px 5px 5px 10px;
        box-shadow: $shadow;
        font-size: 14px;

		span {
            display: none;
        }

        .connection-indicator {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            display: inline-block;
            margin-right: 5px;
        }

        &.connection-online {
            .connection-indicator {
                background: $success;
                box-shadow: 0 0 5px 0 rgba($success, 0.7);
            }
        }

        &.connection-offline {
            .connection-indicator {
                background: $error;
                box-shadow: 0 0 5px 0 rgba($error, 0.7);
            }
        }
    }

    #flash-messages {
        margin-bottom: 4rem;

        &.flash-false {
            margin-bottom: 0;
        }
    }

    table {
        th {
            padding: 0.3rem 0.2rem;
            background: $primary;
            font-size: 18px;
            color: #fff;
            text-align: left;
        }

        td {
            padding: 0.2rem;
            font-size: 14px;
            border-bottom: 1px solid $primary;
        }
    }

    ol {
        list-style: decimal;
        margin-left: 20px;
    }
}

/*
#display-error {
    display: none;
}
*/

.confirm-details {
	border: 1px solid #ccc;

    hr {
        margin-right: calc(128px + 2rem);
    }
}

.qr-code {
	display: inline-block;
    margin-top: 15px;
}

.qr-code + h1 {
}

#qr-code-id {
    max-width: 100%;
    height: auto !important;
}


/* -------------------------------------------------------------------------------
    HTML5 camera photo
   ------------------------------------------------------------------------------- */

#container-circles{
    position:absolute;
    left:50%;
    bottom:90px;
}
#outer-circle{
    left:-37px;
    height:75px;
    width:75px;
    background-color:hsla(0,0%,100%,.4);
    z-index:1;
}
#inner-circle,#outer-circle{
    position:absolute;
    border-radius:50%;
}
#inner-circle{
    left:50%;
    top:38px;
    height:44px;
    width:44px;
    background:#fff;
    margin:-22px 0 0 -22px;
    z-index:2;
}
#inner-circle.is-clicked{
    height:38px;
    width:38px;
    margin:-19px 0 0 -19px;
}
#white-flash.normal{
    position:absolute;
    height:100%;
    width:100%;
    opacity:1;
    -webkit-transition:opacity .9s ease-out;
    -o-transition:opacity .9s ease-out;
    transition:opacity .9s ease-out;
}
#white-flash.do-transition{
    opacity:0;
    background:#fff;
}
#display-error{
    color:#000;
    background-color:#fff;
}
.react-html5-camera-photo{
    position:relative;
    text-align:center;
}
.react-html5-camera-photo>img,
.react-html5-camera-photo>video{
    width:768px;
}
.react-html5-camera-photo>.display-error{
    width:768px;
    margin:0 auto;
}
@media(max-width:768px){
    .react-html5-camera-photo>.display-error,
    .react-html5-camera-photo>img,
    .react-html5-camera-photo>video{
        width:100%;
    }
}
.react-html5-camera-photo-fullscreen>img,
.react-html5-camera-photo-fullscreen>video{
    width:100vw;
    height:100vh;
}
.react-html5-camera-photo-fullscreen>video{
    -o-object-fit:fill;
    object-fit:fill;
}
.react-html5-camera-photo-fullscreen>.display-error{
    width:100vw;
    height:100vh;
}

.demo-image-preview {
    position: relative;
    text-align: center;
}

.demo-image-preview > img {
    width: 768px;
    margin: 0 auto;
}

@media(max-width:768px){
    .demo-image-preview > img {
        width: 100%;
    }
}

/* fullscreen enable by props */
.demo-image-preview-fullscreen > img {
    width: 100vw;
    height:100vh;
}



/* -------------------------------------------------------------------------------
	Phone number input
   ------------------------------------------------------------------------------- */

.PhoneInput {
    display: flex;
    align-items: center;
}

.PhoneInputInput {
    flex: 1;
    min-width: 0;
}

.PhoneInputCountryIcon {
    width: calc(1em * 1.5);
    height: 1em;
}

.PhoneInputCountryIcon--square {
    width: 1em;
}

.PhoneInputCountryIcon--border {
    background-color: rgba(0,0,0,0.1);
    box-shadow: 0 0 0 1px rgba(0,0,0,0.5),
            inset 0 0 0 1px rgba(0,0,0,0.5);
}

.PhoneInputCountryIconImg {
    display: block;
    width: 100%;
    height: 100%;
}

.PhoneInputInternationalIconPhone {
    opacity: 0.8;
}

.PhoneInputInternationalIconGlobe {
    opacity: 0.65;
}

.PhoneInputCountry {
    position: relative;
    align-self: stretch;
    display: flex;
    align-items: center;
    margin-right: 0.35em;
}

.PhoneInputCountrySelect {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    border: 0;
    opacity: 0;
    cursor: pointer;
}

.PhoneInputCountrySelect[disabled] {
    cursor: default;
}

.PhoneInputCountrySelectArrow {
    display: block;
    content: '';
    width: 0.3em;
    height: 0.3em;
    margin-left: 0.35em;
    border-style: solid;
    border-color: inherit;
    border-top-width: 0;
    border-bottom-width: 1px;
    border-left-width: 0;
    border-right-width: 1px;
    transform: rotate(45deg);
    opacity: 0.45;
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon + .PhoneInputCountrySelectArrow {
    opacity: 1;
    color: #03b2cb;
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
    box-shadow: 0 0 0 1px #03b2cb,
            inset 0 0 0 1px #03b2cb;
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon .PhoneInputInternationalIconGlobe {
    opacity: 1;
    color: #03b2cb;
}

.returning-visitor-photo {
    width: 180px;
    height: 180px;
    position: absolute;
    right: -1.5rem;
    top: -1.5rem;
    transform: scaleX(-1);
    display: inline-block;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.flip-img {
    transform: scaleX(-1);
    display: inline-block;
}

.key-form {
    position: relative;
}

/* -------------------------------------------------------------------------------
    Ads
   ------------------------------------------------------------------------------- */

#ads {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: #fff;
    opacity: 0;
    pointer-events: none;
    z-index: -1;
    transition: 0.25s ease opacity;
    
    &.active {
        z-index: 10000;
        pointer-events: inherit;
        opacity: 1;
    }
    
    .ad {
        pointer-events: none;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        opacity: 0;
        transition: 0.25s ease opacity;
        position: absolute;
        top: 0;
        left: 0;
        
        .ad-text {
            position: absolute;
            top: 30px;
            left: 30px;
            padding-left: 10px;
            padding-right: 10px;
            max-width: 40%;
            /*
            font-size: 2rem;
            font-weight: bold;
            background: rgba(255,255,255, 0.5);
            */
                
            p:last-child {
                margin-bottom: 0;
            }
        }
        
        &.active {
            opacity: 1;
        }
    }
}

.ads-export-filters {
    .form-error {
        display: none;
    }
    
    .form-select {
        padding: 0.8rem 2rem 0.8rem 0.8rem;
    }
}

#advertisers {
    .advertiser {
        width: 50%;
        max-width: 400px;
        margin: 0 auto;
        
        .advertiser-logo {
            margin: 0 auto;
            width: 150px;
            height: 100px;
            background-size: contain;
        }
    }   
}

.thanks-page {
    .qr-code {
        position: relative;
        right: inherit;
        top: inherit;
        transform: none;
    }
}

#qr-badge {
    display: none;
    border: 1px solid #000;
    margin: 2rem auto;
}

.select-modal-columns {
    columns: 3;
}


/* -------------------------------------------------------------------------------
    Media Queries
   ------------------------------------------------------------------------------- */
@media only screen and (min-width: 900px) and (orientation : landscape) {
    .demo-image-preview > img {
        width: 300px;
        margin: 0 auto;
    }

    .react-html5-camera-photo>img,
    .react-html5-camera-photo>video,
    .react-html5-camera-photo>.display-error{
        width:300px;
    }

    .qr-code {
        width: 256px;
        height: 256px;
        position: absolute;
        right: 2rem;
        top: 50%;
        margin: 0;
        transform: translateY(-50%);

        canvas {
            width: 256px !important;
            height: 256px !important;
        }
    }

    .checkout-container {
        margin: 0 auto;
        max-width: 720px;

        textarea {
            height: 80px;
        }
    }

    .key-form {
        margin: 0 auto;
        max-width: 720px;
    }

    .checkin-form {
        &.form-search {
            margin: 0 auto;
            max-width: 720px;
        }

        &.form-scan {
            .qr-reader {
                width: 300px !important;
				height: 300px !important;
                margin: 0 auto;
            }
        }

        &.form-personal {
            .form-el {
                width: 33.333%;
            }
        }
    }

    .confirm-details {
        .confirm-user-details {
            width: calc(66% - 300px);
        }
    }

    #checkin {
        p {
            overflow-wrap: break-word;
        }

        img {
            -webkit-touch-callout:none;
            -webkit-user-select:none;
            -khtml-user-select:none;
            -moz-user-select:none;
            -ms-user-select:none;
            user-select:none;
            -webkit-tap-highlight-color:rgba(0,0,0,0);
        }

        > div {
            .box {
                max-width: calc(100% - 40px);
                .text-center {
                    img {
                        display: inline;
                    }
                }

                > div,
                > form > div {
                    > h2 + div {
                        margin-top: 0;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width : 1200px) {
}

@media only screen and (max-width : 992px) {
}

@media only screen and (max-width : 768px) {
    #content {
        padding: $padding $padding;
    }
    
    #flash-messages {
        font-size: 12px;
    }
    
    #checkin {
        align-items: flex-start;
        
        > .items-center {
            align-items: flex-start;
        }
        
        .flex.flex-wrap.items-center.justify-center.mt-10 {
            margin-top: 1.5rem;
        }

        .btn {
            width: 100%;
            margin-left: 0;
            margin-right: 0;
            text-align: center;
            justify-content: center;
        }
    }
    
    #advertisers {
        .advertiser {
            width: 100%;
        }
    }
    
    .confirm-details {
        hr {
            margin-right: 0;
        }

        .demo-image-preview,
        .qr-code {
            display: inline-block;
            width: 50%;
            padding: 10px;
            margin-top: 0;
            
            &:first-child {
                padding-right: 5px;
            }
            
            &:last-child {
                padding-left: 5px;
            }
        }
        
        .qr-code {
            canvas {
                width: 100%;
                height: auto;
            }
        }
    }
    
    
    
    .scroll-me {
        &:before {
            display: none;
        }

        > div {
            height: calc(100vh - 25rem);
            min-height: 250px;            
        }
    }
}

@media only screen and (max-width : 576px) {
}

.print-only {
    display: none !important;
}

@media print {
    $print_width = 488px; // was 330
    $img_size = 148px; // was 100
    $img_holder = 168px; // was 120

    html, body {
        margin: 0;
        padding: 0;
        background: #fff !important;
        font-size: 10.3px;
    }

    #ads {
        display: none;
    }
    
    .print-show, .print-only {
        display: block !important;
    }
    
    .print-hide {
        display: none !important;
    }

    @page {
        size: auto;
        margin: 0mm;
    }
    
    #checkin {
        padding: 0 !important;
        display: block;
        
        > .min-h-screen {
            min-height: inherit !important;
            display: block !important;
        }
        
        h1 {
            font-size: 2.8rem;
        }
        
        h2 {
            font-size: 1.4rem;
        }
        
        h2, h6 {
            //white-space: nowrap;
        }
        
        .connection-status,
        h2,
        button,
        hr {
            display: none !important;
        }
        
        > div:first-child {
            > .box {
                width: $print_width;
            }
        }
        
        .box {
            max-width: 100% !important;
            padding: 0 !important;
            margin: 0 !important;
            box-shadow: none !important;
            border: none !important;
        }
        
        .mb-8 {
            margin-bottom: 0 !important;
        }
        
        .mt-10 {
            margin-top: 0 !important;
        }
        
        .demo-image-preview > img,
        .qr-code canvas {
            width: $img_size !important;
            height: $img_size !important;
        }
        
        .qr-code {
            top: 0;
            right: 0;
            width: $img_size;
            height: $img_size;
            transform: none;
            position: absolute;
            margin-top: 10px;
        }
        
        .demo-image-preview {
            text-align: left;
            position: absolute;
            height: $img_holder;
            overflow: hidden;
            width: $img_size;
            
            > img {
                width: $img_holder !important;
                height: $img_holder !important;
                max-width: inherit;
                margin-left: -10px;
                transform: scaleX(-1);
                
                &.returning-visitor-photo {
                    margin-top: 10px;
                    margin-left: 0;
                    position: relative;
                }
            }
        }
        
        .confirm-details {
            width: $print_width;
            height: $img_holder;
            margin-top: 0;
            
            // portrait
            transform: rotate(90deg) translate(50.2%, -85px);
            transform-origin: top center;
            
            h1 {
                margin-bottom: 5px !important;
                line-height: 1.1;
            }
            
            h2 {
                display: block !important;
                font-size: 1.6rem !important;
                line-height: 1.1;
                margin-bottom: 1rem;
            }
            
            h6 {
                font-size: 1.4rem !important;
                line-height: 1.1;
                margin-bottom: 10px;
                margin-top: 1rem;
            }
            
            .department {
                display: none !important;
            }
            
            .confirm-user-details {
                margin-top: 0px;
            }

            > div:nth-child(1) {
                position: absolute;
                width: $print_width;
                height: $img_size;
            }
            
            > div:nth-child(2) {
                width: 336px;
                padding-left: 158px;
            }
        }
    }
    
    .dashboard {
        #headers, #nav, h1, .btn, .dashboard-controls   {
            display: none;
        }
    }
}

.nowrap {
    white-space: nowrap !important;
}

.dashboard-controls {
    .form-error {
        display: none;
    }
}

.dashboard-border {
    height: 1px;
    background: #ccc;
}