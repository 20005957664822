@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("/fonts/vendor/typeface-ibm-plex-sans/files/ibm-plex-sans-latin-100.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url("/fonts/vendor/typeface-ibm-plex-sans/files/ibm-plex-sans-latin-200.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("/fonts/vendor/typeface-ibm-plex-sans/files/ibm-plex-sans-latin-300.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/fonts/vendor/typeface-ibm-plex-sans/files/ibm-plex-sans-latin-400.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("/fonts/vendor/typeface-ibm-plex-sans/files/ibm-plex-sans-latin-500.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("/fonts/vendor/typeface-ibm-plex-sans/files/ibm-plex-sans-latin-600.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("/fonts/vendor/typeface-ibm-plex-sans/files/ibm-plex-sans-latin-700.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* -------------------------------------------------------------------------------
    Variables
   ------------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------------
    HTML Elements
   ------------------------------------------------------------------------------- */
html {
  height: 100%;
}
body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  font-family: 'IBM Plex Sans', sans-serif;
  color: #061a29;
  background: #eee;
}
body h1,
body .h1,
body h2,
body .h2,
body h3,
body .h3,
body h4,
body .h4,
body h5,
body .h5,
body h6,
body .h6,
body blockquote,
body th {
  font-weight: 500;
  margin-bottom: 1rem;
}
body h1,
body .h1 {
  font-size: 2rem;
  margin-bottom: 1.5rem;
}
body h1 .cl-primary,
body .h1 .cl-primary {
  transition: 0.35s ease all;
}
body h2,
body .h2 {
  font-size: 1.8rem;
  margin-bottom: 1.2rem;
}
body h3,
body .h3 {
  font-size: 1.6rem;
}
body h4,
body .h4 {
  font-size: 1.4rem;
}
body h5,
body .h5 {
  font-size: 1.3rem;
}
body h6,
body .h6 {
  font-size: 1.2rem;
}
body p {
  font-size: 1rem;
  margin-bottom: 1rem;
}
#checkin.tablet-checkin * {
  user-select: none;
}
#checkin p {
  overflow-wrap: break-word;
}
:focus,
:visited,
:hover,
:active,
a:focus,
a:visited,
a:hover,
a:active {
  outline: 0 !important;
  text-decoration: none;
}
/* -------------------------------------------------------------------------------
    Custom Helpers
   ------------------------------------------------------------------------------- */
.bg {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.min-h-screen {
  min-height: calc(100vh - 4rem - 31px);
}
.scroll-me {
  position: relative;
  border: 1px solid #eee;
}
.scroll-me > div {
  padding: 10px;
  height: calc(100vh - 17rem);
  min-height: 300px;
  overflow-y: scroll;
}
.scroll-me:before {
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 70px;
  background: linear-gradient(180deg, rgba(255,255,255,0) 0%, #fff 100%);
  content: '';
  backface-visibility: hidden;
}
.btn {
  font-size: 1rem;
  padding: 0.75rem 1.5rem;
  border-radius: 0.25rem;
  color: #fff;
  font-weight: 500;
  white-space: nowrap;
  background: #24aae1;
  transition: 0.35s ease all;
  position: relative;
  border: 2px solid #eee;
}
.btn.btn-sm {
  font-size: 0.85rem;
  padding: 0.5rem 1rem;
}
.btn:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  border-width: 8px;
  border-color: #eee #eee transparent transparent;
  background: rgba(6,26,41,0.3);
  -moz-border-radius: 0 0 0 3px;
  border-radius: 0 0 0 3px;
  display: block;
  width: 0;
  transition: 0.35s ease all;
  backface-visibility: hidden;
}
.btn:hover {
  background: rgba(36,170,225,0.8);
}
.btn:hover:before {
  border-width: 0;
}
.btn.btn-primary {
  background: #24aae1;
}
.btn.btn-primary:hover {
  background: rgba(36,170,225,0.8);
}
.btn.btn-highlight {
  background: #8d09d2;
}
.btn.btn-highlight:hover {
  background: rgba(141,9,210,0.8);
}
.btn.btn-dark {
  background: #061a29;
}
.btn.btn-dark:hover {
  background: rgba(6,26,41,0.8);
}
.btn.btn-light {
  background: #24aae1;
}
.btn.btn-light:hover {
  background: rgba(36,170,225,0.8);
}
.btn.btn-error {
  background: #ef443a;
}
.btn.btn-error:hover {
  background: rgba(239,68,58,0.8);
}
.btn.btn-warning {
  background: #fbbf24;
}
.btn.btn-warning:hover {
  background: rgba(251,191,36,0.8);
}
.btn.btn-success {
  background: #059669;
}
.btn.btn-success:hover {
  background: rgba(5,150,105,0.8);
}
.form-box .btn {
  border: 2px solid #fff;
}
.form-box .btn:before {
  border-color: #fff #fff transparent transparent;
}
.auth-page .max-w-md {
  max-width: 24rem;
}
.auth-page .box.form-box {
  padding: 2.4rem 1.8rem 2.4rem 1.8rem;
}
.bg-white {
  background: #fff;
}
.bg-black {
  background: #061a29;
}
.bg-primary {
  background: #24aae1;
  color: #fff;
}
.bg-highlight {
  background: #8d09d2;
}
.bg-dark {
  background: #061a29;
  color: #fff;
}
.bg-light {
  background: #24aae1;
}
.bg-lightgrey {
  background: #ccc;
}
.bg-verylightgrey {
  background: #eee;
}
.bg-error {
  background: #ef443a;
}
.bg-success {
  background: #059669;
}
.cl-white {
  color: #fff;
}
.cl-black {
  color: #061a29;
}
.cl-primary {
  color: #24aae1;
}
.cl-highlight {
  color: #8d09d2;
}
.cl-dark {
  color: #061a29;
}
.cl-light {
  color: #24aae1;
}
.cl-lightgrey {
  color: #ccc;
}
.cl-error {
  color: #ef443a;
}
.cl-success {
  color: #059669;
}
.m-0 {
  margin: 0 !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.mr-0 {
  margin-right: 0 !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.ml-0 {
  margin-left: 0 !important;
}
.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.p-0 {
  padding: 0 !important;
}
.pt-0 {
  padding-top: 0 !important;
}
.pr-0 {
  padding-right: 0 !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.pl-0 {
  padding-left: 0 !important;
}
.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.shadow {
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.1), 0 1px 2px 0 rgba(0,0,0,0.06) !important;
}
.box {
  background: #fff;
  color: #061a29;
  border-radius: 5px;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.1), 0 1px 2px 0 rgba(0,0,0,0.06);
  padding: 1.5rem;
}
.box.form-box {
  padding: 2rem 1.5rem;
}
.hidden {
  display: none;
}
@media only screen and (max-width: 767px) {
  .hidden-mobi {
    display: none;
  }
}
@media only screen and (min-width: 768px) {
  .hidden-desktop {
    display: none;
  }
}
.overlay {
  display: block;
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0.35);
  backdrop-filter: blur(2px);
  backface-visibility: hidden;
}
#checkin .modal {
  top: 5vh;
  max-height: 90vh;
  height: auto;
  transform: translateX(-50%);
}
.modal-wrapper .overlay {
  transition: 0.3s ease opacity;
}
.modal-wrapper .overlay.hidden {
  opacity: 0;
  pointer-events: none;
}
.modal-wrapper .modal {
  z-index: 12;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 700px;
  background: #fff;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.1), 0 1px 2px 0 rgba(0,0,0,0.06);
  padding: 1.5rem;
  border-radius: 5px;
  opacity: 0;
  pointer-events: none;
  overflow-y: scroll;
  max-height: 90vh;
}
.modal-wrapper .modal.show {
  opacity: 1;
  pointer-events: inherit;
}
.modal-wrapper .modal .close-icon {
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}
.w-auto {
  width: auto !important;
}
.chart-container {
  height: 350px;
}
.chart-container.chart-higher {
  height: 500px;
}
/* -------------------------------------------------------------------------------
    Navigation
   ------------------------------------------------------------------------------- */
.logo h3 {
  margin: 0;
}
#headers #left,
#headers #subnav {
  padding: 1rem 2rem;
}
#headers #subnav {
  background-color: #fff !important;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.05), 0 1px 2px 0 rgba(0,0,0,0.03);
}
#content {
  padding: 2rem 4rem;
}
#nav {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
#nav hr {
  opacity: 0.2;
}
#nav .nav-item {
  padding: 0.5rem 2rem;
  transition: 0.35s ease all;
  font-weight: 400;
}
#nav .nav-item:hover {
  background: rgba(6,26,41,0.1);
}
#nav .nav-item.active {
  background: rgba(6,26,41,0.2);
}
#nav .nav-item .nav-icon {
  width: 1.6rem !important;
  transform: translate(-5px);
}
#nav-mobile .nav-item {
  padding: 0.4rem 2rem;
  transition: 0.35s ease all;
}
#nav-mobile .nav-item.active {
  background: #24aae1;
  color: #fff;
}
#nav-mobile .nav-item .nav-icon {
  width: 1.6rem !important;
  transform: translate(-5px);
}
.dropdown a:hover {
  background: #24aae1;
  color: #fff;
}
/* -------------------------------------------------------------------------------
    Tables
   ------------------------------------------------------------------------------- */
table.table th {
  text-align: left;
  padding: 0.5rem 0.2rem 0.5rem 1.5rem;
  border-bottom: 1px solid rgba(6,26,41,0.1);
  font-size: 1rem;
}
table.table .td-pad {
  padding: 0.3rem 0.2rem 0.3rem 1.5rem;
}
table.table td {
  border-bottom: 1px solid rgba(6,26,41,0.05);
  font-size: 0.9rem;
}
table.table td a,
table.table td span {
  padding: 0.3rem 0.2rem 0.3rem 1.5rem;
}
table.table tbody tr {
  transition: 0.35s ease all;
}
table.table tbody tr:hover {
  background: rgba(6,26,41,0.05);
}
table.table a.table-icon {
  width: 2rem;
  display: inline-block;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  opacity: 0.3;
  transition: 0.35s ease all;
}
table.table a.table-icon .nav-icon {
  pointer-events: none;
}
table.table a.table-icon:hover {
  opacity: 1;
  color: #24aae1;
}
table.table a.table-icon:last-child {
  margin-right: 1rem;
}
/* -------------------------------------------------------------------------------
    Tables
   ------------------------------------------------------------------------------- */
.pagination a,
.pagination > div {
  padding: 0.2rem 0.4rem;
  margin: 0 0.2rem;
  min-width: 2rem;
  text-align: center;
  transition: 0.35s ease all;
}
.pagination a.active,
.pagination > div.active {
  background: #24aae1 !important;
  color: #fff;
}
.pagination a {
  background: #fff;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.1), 0 1px 2px 0 rgba(0,0,0,0.06);
}
.pagination a:hover {
  background: rgba(6,26,41,0.05);
}
/* -------------------------------------------------------------------------------
    Forms
   ------------------------------------------------------------------------------- */
.toggle-checkbox:checked {
  right: 0;
  border: 1px solid #ccc;
  border-color: #68d391;
}
.toggle-checkbox:checked + .toggle-label {
  background: #24aae1;
  background-color: #68d391;
}
.form-el {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-bottom: 1rem;
}
.form-el .form-label {
  margin-bottom: 0.3rem;
  display: block;
  color: #061a29;
  font-weight: 500;
}
.form-el > div {
  display: block !important;
  position: relative;
  z-index: 2;
}
.form-el > div.inline {
  display: inline !important;
}
.form-el > div.form-error {
  z-index: 1;
}
.form-el > div > div div.autocomplete-item {
  padding: 0.2rem 0.5rem;
}
.form-el .form-textarea,
.form-el .form-input,
.form-el .form-select,
.form-el .form-file,
.form-el input[aria-autocomplete="list"] {
  outline: 0;
  border: 1px solid #ccc;
  box-shadow: none !important;
  color: #061a29;
  padding: 0.5rem;
  line-height: 1.5;
  display: block;
  width: 100%;
  border-radius: 0.25rem;
  appearance: none;
  position: relative;
}
.form-el .form-textarea.PhoneInput,
.form-el .form-input.PhoneInput,
.form-el .form-select.PhoneInput,
.form-el .form-file.PhoneInput,
.form-el input[aria-autocomplete="list"].PhoneInput {
  display: flex !important;
}
.form-el .form-textarea[readonly],
.form-el .form-input[readonly],
.form-el .form-select[readonly],
.form-el .form-file[readonly],
.form-el input[aria-autocomplete="list"][readonly] {
  color: #888;
}
.form-el .form-textarea[readonly]:focus,
.form-el .form-input[readonly]:focus,
.form-el .form-select[readonly]:focus,
.form-el .form-file[readonly]:focus,
.form-el input[aria-autocomplete="list"][readonly]:focus {
  border: 1px solid #ccc;
}
.form-el .form-textarea:focus,
.form-el .form-input:focus,
.form-el .form-select:focus,
.form-el .form-file:focus,
.form-el input[aria-autocomplete="list"]:focus {
  border: 1px solid #24aae1;
}
.form-el .form-file {
  max-width: 300px;
}
.form-el .form-textarea.error,
.form-el .form-input.error,
.form-el .form-select.error {
  border-color: #ef443a;
}
.form-el .form-error {
  margin-top: 0.2rem;
  color: #ef443a;
}
.form-el .form-radio,
.form-el .form-check {
  position: relative;
  margin-left: 3px;
  cursor: pointer;
  opacity: 0;
}
.form-el .form-radio + label,
.form-el .form-check + label {
  position: relative;
  margin-left: -20px;
  padding-left: 25px;
  padding-right: 10px;
  cursor: pointer;
  transition: 0.35s ease all;
  color: #061a29;
}
.form-el .form-radio + label span,
.form-el .form-check + label span {
  width: 20px;
  height: 20px;
  display: block;
  border: 2px solid #24aae1;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: calc(-0.5rem + 4px);
  cursor: pointer;
  transition: 0.35s ease all;
}
.form-el .form-radio + label span:after,
.form-el .form-check + label span:after {
  content: '';
  width: 6px;
  height: 6px;
  margin-top: -3px;
  margin-left: -3px;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  background: #fff;
  display: block;
  transition: 0.35s ease all;
}
.form-el .form-radio:checked + label,
.form-el .form-radio:active + label,
.form-el .form-check:checked + label,
.form-el .form-check:active + label {
  opacity: 1;
}
.form-el .form-radio:checked + label span,
.form-el .form-radio:active + label span,
.form-el .form-check:checked + label span,
.form-el .form-check:active + label span {
  opacity: 1;
}
.form-el .form-radio:checked + label span:after,
.form-el .form-radio:active + label span:after,
.form-el .form-check:checked + label span:after,
.form-el .form-check:active + label span:after {
  background: #24aae1;
  opacity: 1;
}
.form-el .form-check + label span {
  border-radius: 0 !important;
}
.form-el .form-check + label span:after {
  width: 10px;
  height: 10px;
  margin-top: -5px;
  margin-left: -5px;
  border-radius: 0 !important;
}
.form-el .form-check:checked + label,
.form-el .form-check:active + label {
  opacity: 1;
}
.form-el .form-check:checked + label span,
.form-el .form-check:active + label span {
  opacity: 1;
}
.form-el .form-check:checked + label span:after,
.form-el .form-check:active + label span:after {
  background: url("../img/check.svg") no-repeat center center;
  background-size: cover;
  opacity: 1;
}
.form-el .form-radio:hover + label span,
.form-el .form-radio:focus + label span,
.form-el .form-radio:active + label span,
.form-el .form-check:hover + label span,
.form-el .form-check:focus + label span,
.form-el .form-check:active + label span {
  border-color: #167aa2;
}
.form-el .form-radio:hover + label span:after,
.form-el .form-radio:focus + label span:after,
.form-el .form-radio:active + label span:after,
.form-el .form-check:hover + label span:after,
.form-el .form-check:focus + label span:after,
.form-el .form-check:active + label span:after {
  opacity: 1;
}
.form-el .caption-group-wrapper {
  white-space: nowrap;
}
form .btn {
  margin: 0.5rem 0.5rem 0 0.5rem;
}
#flash-messages {
  margin-bottom: 0rem;
}
.auth-page #flash-messages {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
::-webkit-input-placeholder {
  color: #ccc !important;
}
:-moz-placeholder {
  color: #ccc !important;
}
::-moz-placeholder {
  color: #ccc !important;
}
:-ms-input-placeholder {
  color: #ccc !important;
}
.placeholder {
  color: #ccc !important;
}
/* -------------------------------------------------------------------------------
    Component Specific
   ------------------------------------------------------------------------------- */
#location-settings section.location-section.active .location-section-head h2 {
  background: rgba(6,26,41,0.2) !important;
}
#location-settings section.location-section.active .location-section-body {
  display: block;
}
#location-settings section.location-section .location-section-head {
  background: #24aae1;
}
#location-settings section.location-section .location-section-head h2 {
  cursor: pointer;
  color: #fff;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-weight: 400;
  border-bottom: 1px solid rgba(6,26,41,0.1);
  transition: 0.35s ease all;
}
#location-settings section.location-section .location-section-head h2 .num {
  font-weight: 200;
}
#location-settings section.location-section .location-section-head h2:hover {
  background: rgba(6,26,41,0.1);
}
#location-settings section.location-section .location-section-body {
  padding: 2rem 1.5rem;
  display: none;
}
/* Home Screen */
.home-screen .btn {
  display: block;
  text-align: center;
  margin-top: 1rem;
}
/* Checkin */
#checkin .btn {
  border: none;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  min-width: 100px;
}
#checkin .btn:before {
  display: none;
}
#checkin div.flex.items-center.justify-between > button:first-child {
  margin-left: 0.5rem;
}
#checkin div.flex.items-center.justify-between > button:last-child {
  margin-right: 0.5rem;
}
#checkin > div > .box {
  width: 100%;
  max-width: 720px;
}
#checkin > div > .box.screen-legal,
#checkin > div > .box.screen-privacy,
#checkin > div > .box.screen-key_terms {
  min-height: calc(100vh - 3rem);
}
#checkin > div > .box > div > h2,
#checkin > div > .box > form > div > h2 {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
#checkin > div > .box > div > h2:first-child,
#checkin > div > .box > form > div > h2:first-child {
  margin-top: -1.5rem;
  padding-top: 1.5rem;
  padding-bottom: 0.5rem;
}
#checkin > div > .box > div > h2 + div,
#checkin > div > .box > form > div > h2 + div {
  margin-top: 1.5rem;
}
#checkin .connection-status {
  position: fixed;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background: #fff;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: 5px 5px 5px 10px;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.1), 0 1px 2px 0 rgba(0,0,0,0.06);
  font-size: 14px;
}
#checkin .connection-status span {
  display: none;
}
#checkin .connection-status .connection-indicator {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}
#checkin .connection-status.connection-online .connection-indicator {
  background: #059669;
  box-shadow: 0 0 5px 0 rgba(5,150,105,0.7);
}
#checkin .connection-status.connection-offline .connection-indicator {
  background: #ef443a;
  box-shadow: 0 0 5px 0 rgba(239,68,58,0.7);
}
#checkin #flash-messages {
  margin-bottom: 4rem;
}
#checkin #flash-messages.flash-false {
  margin-bottom: 0;
}
#checkin table th {
  padding: 0.3rem 0.2rem;
  background: #24aae1;
  font-size: 18px;
  color: #fff;
  text-align: left;
}
#checkin table td {
  padding: 0.2rem;
  font-size: 14px;
  border-bottom: 1px solid #24aae1;
}
#checkin ol {
  list-style: decimal;
  margin-left: 20px;
}
/*
#display-error {
    display: none;
}
*/
.confirm-details {
  border: 1px solid #ccc;
}
.confirm-details hr {
  margin-right: calc(128px + 2rem);
}
.qr-code {
  display: inline-block;
  margin-top: 15px;
}
#qr-code-id {
  max-width: 100%;
  height: auto !important;
}
/* -------------------------------------------------------------------------------
    HTML5 camera photo
   ------------------------------------------------------------------------------- */
#container-circles {
  position: absolute;
  left: 50%;
  bottom: 90px;
}
#outer-circle {
  left: -37px;
  height: 75px;
  width: 75px;
  background-color: rgba(255,255,255,0.4);
  z-index: 1;
}
#inner-circle,
#outer-circle {
  position: absolute;
  border-radius: 50%;
}
#inner-circle {
  left: 50%;
  top: 38px;
  height: 44px;
  width: 44px;
  background: #fff;
  margin: -22px 0 0 -22px;
  z-index: 2;
}
#inner-circle.is-clicked {
  height: 38px;
  width: 38px;
  margin: -19px 0 0 -19px;
}
#white-flash.normal {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 1;
  -webkit-transition: opacity 0.9s ease-out;
  -o-transition: opacity 0.9s ease-out;
  transition: opacity 0.9s ease-out;
}
#white-flash.do-transition {
  opacity: 0;
  background: #fff;
}
#display-error {
  color: #000;
  background-color: #fff;
}
.react-html5-camera-photo {
  position: relative;
  text-align: center;
}
.react-html5-camera-photo>img,
.react-html5-camera-photo>video {
  width: 768px;
}
.react-html5-camera-photo>.display-error {
  width: 768px;
  margin: 0 auto;
}
@media (max-width: 768px) {
  .react-html5-camera-photo>.display-error,
  .react-html5-camera-photo>img,
  .react-html5-camera-photo>video {
    width: 100%;
  }
}
.react-html5-camera-photo-fullscreen>img,
.react-html5-camera-photo-fullscreen>video {
  width: 100vw;
  height: 100vh;
}
.react-html5-camera-photo-fullscreen>video {
  -o-object-fit: fill;
  object-fit: fill;
}
.react-html5-camera-photo-fullscreen>.display-error {
  width: 100vw;
  height: 100vh;
}
.demo-image-preview {
  position: relative;
  text-align: center;
}
.demo-image-preview > img {
  width: 768px;
  margin: 0 auto;
}
@media (max-width: 768px) {
  .demo-image-preview > img {
    width: 100%;
  }
}
/* fullscreen enable by props */
.demo-image-preview-fullscreen > img {
  width: 100vw;
  height: 100vh;
}
/* -------------------------------------------------------------------------------
	Phone number input
   ------------------------------------------------------------------------------- */
.PhoneInput {
  display: flex;
  align-items: center;
}
.PhoneInputInput {
  flex: 1;
  min-width: 0;
}
.PhoneInputCountryIcon {
  width: calc(1em * 1.5);
  height: 1em;
}
.PhoneInputCountryIcon--square {
  width: 1em;
}
.PhoneInputCountryIcon--border {
  background-color: rgba(0,0,0,0.1);
  box-shadow: 0 0 0 1px rgba(0,0,0,0.5), inset 0 0 0 1px rgba(0,0,0,0.5);
}
.PhoneInputCountryIconImg {
  display: block;
  width: 100%;
  height: 100%;
}
.PhoneInputInternationalIconPhone {
  opacity: 0.8;
}
.PhoneInputInternationalIconGlobe {
  opacity: 0.65;
}
.PhoneInputCountry {
  position: relative;
  align-self: stretch;
  display: flex;
  align-items: center;
  margin-right: 0.35em;
}
.PhoneInputCountrySelect {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  border: 0;
  opacity: 0;
  cursor: pointer;
}
.PhoneInputCountrySelect[disabled] {
  cursor: default;
}
.PhoneInputCountrySelectArrow {
  display: block;
  content: '';
  width: 0.3em;
  height: 0.3em;
  margin-left: 0.35em;
  border-style: solid;
  border-color: inherit;
  border-top-width: 0;
  border-bottom-width: 1px;
  border-left-width: 0;
  border-right-width: 1px;
  transform: rotate(45deg);
  opacity: 0.45;
}
.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon + .PhoneInputCountrySelectArrow {
  opacity: 1;
  color: #03b2cb;
}
.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
  box-shadow: 0 0 0 1px #03b2cb, inset 0 0 0 1px #03b2cb;
}
.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon .PhoneInputInternationalIconGlobe {
  opacity: 1;
  color: #03b2cb;
}
.returning-visitor-photo {
  width: 180px;
  height: 180px;
  position: absolute;
  right: -1.5rem;
  top: -1.5rem;
  transform: scaleX(-1);
  display: inline-block;
  border-top-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.flip-img {
  transform: scaleX(-1);
  display: inline-block;
}
.key-form {
  position: relative;
}
/* -------------------------------------------------------------------------------
    Ads
   ------------------------------------------------------------------------------- */
#ads {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  opacity: 0;
  pointer-events: none;
  z-index: -1;
  transition: 0.25s ease opacity;
}
#ads.active {
  z-index: 10000;
  pointer-events: inherit;
  opacity: 1;
}
#ads .ad {
  pointer-events: none;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  opacity: 0;
  transition: 0.25s ease opacity;
  position: absolute;
  top: 0;
  left: 0;
}
#ads .ad .ad-text {
  position: absolute;
  top: 30px;
  left: 30px;
  padding-left: 10px;
  padding-right: 10px;
  max-width: 40%;
/*
            font-size: 2rem;
            font-weight: bold;
            background: rgba(255,255,255, 0.5);
            */
}
#ads .ad .ad-text p:last-child {
  margin-bottom: 0;
}
#ads .ad.active {
  opacity: 1;
}
.ads-export-filters .form-error {
  display: none;
}
.ads-export-filters .form-select {
  padding: 0.8rem 2rem 0.8rem 0.8rem;
}
#advertisers .advertiser {
  width: 50%;
  max-width: 400px;
  margin: 0 auto;
}
#advertisers .advertiser .advertiser-logo {
  margin: 0 auto;
  width: 150px;
  height: 100px;
  background-size: contain;
}
.thanks-page .qr-code {
  position: relative;
  right: inherit;
  top: inherit;
  transform: none;
}
#qr-badge {
  display: none;
  border: 1px solid #000;
  margin: 2rem auto;
}
.select-modal-columns {
  columns: 3;
}
/* -------------------------------------------------------------------------------
    Media Queries
   ------------------------------------------------------------------------------- */
@media only screen and (min-width: 900px) and (orientation: landscape) {
  .demo-image-preview > img {
    width: 300px;
    margin: 0 auto;
  }
  .react-html5-camera-photo>img,
  .react-html5-camera-photo>video,
  .react-html5-camera-photo>.display-error {
    width: 300px;
  }
  .qr-code {
    width: 256px;
    height: 256px;
    position: absolute;
    right: 2rem;
    top: 50%;
    margin: 0;
    transform: translateY(-50%);
  }
  .qr-code canvas {
    width: 256px !important;
    height: 256px !important;
  }
  .checkout-container {
    margin: 0 auto;
    max-width: 720px;
  }
  .checkout-container textarea {
    height: 80px;
  }
  .key-form {
    margin: 0 auto;
    max-width: 720px;
  }
  .checkin-form.form-search {
    margin: 0 auto;
    max-width: 720px;
  }
  .checkin-form.form-scan .qr-reader {
    width: 300px !important;
    height: 300px !important;
    margin: 0 auto;
  }
  .checkin-form.form-personal .form-el {
    width: 33.333%;
  }
  .confirm-details .confirm-user-details {
    width: calc(66% - 300px);
  }
  #checkin p {
    overflow-wrap: break-word;
  }
  #checkin img {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
  }
  #checkin > div .box {
    max-width: calc(100% - 40px);
  }
  #checkin > div .box .text-center img {
    display: inline;
  }
  #checkin > div .box > div > h2 + div,
  #checkin > div .box > form > div > h2 + div {
    margin-top: 0;
  }
}
@media only screen and (max-width: 768px) {
  #content {
    padding: 2rem 2rem;
  }
  #flash-messages {
    font-size: 12px;
  }
  #checkin {
    align-items: flex-start;
  }
  #checkin > .items-center {
    align-items: flex-start;
  }
  #checkin .flex.flex-wrap.items-center.justify-center.mt-10 {
    margin-top: 1.5rem;
  }
  #checkin .btn {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    text-align: center;
    justify-content: center;
  }
  #advertisers .advertiser {
    width: 100%;
  }
  .confirm-details hr {
    margin-right: 0;
  }
  .confirm-details .demo-image-preview,
  .confirm-details .qr-code {
    display: inline-block;
    width: 50%;
    padding: 10px;
    margin-top: 0;
  }
  .confirm-details .demo-image-preview:first-child,
  .confirm-details .qr-code:first-child {
    padding-right: 5px;
  }
  .confirm-details .demo-image-preview:last-child,
  .confirm-details .qr-code:last-child {
    padding-left: 5px;
  }
  .confirm-details .qr-code canvas {
    width: 100%;
    height: auto;
  }
  .scroll-me:before {
    display: none;
  }
  .scroll-me > div {
    height: calc(100vh - 25rem);
    min-height: 250px;
  }
}
.print-only {
  display: none !important;
}
@media print {
  html,
  body {
    margin: 0;
    padding: 0;
    background: #fff !important;
    font-size: 10.3px;
  }
  #ads {
    display: none;
  }
  .print-show,
  .print-only {
    display: block !important;
  }
  .print-hide {
    display: none !important;
  }
  @page {
    size: auto;
    margin: 0mm;
  }
  #checkin {
    padding: 0 !important;
    display: block;
  }
  #checkin > .min-h-screen {
    min-height: inherit !important;
    display: block !important;
  }
  #checkin h1 {
    font-size: 2.8rem;
  }
  #checkin h2 {
    font-size: 1.4rem;
  }
  #checkin .connection-status,
  #checkin h2,
  #checkin button,
  #checkin hr {
    display: none !important;
  }
  #checkin > div:first-child > .box {
    width: 488px;
  }
  #checkin .box {
    max-width: 100% !important;
    padding: 0 !important;
    margin: 0 !important;
    box-shadow: none !important;
    border: none !important;
  }
  #checkin .mb-8 {
    margin-bottom: 0 !important;
  }
  #checkin .mt-10 {
    margin-top: 0 !important;
  }
  #checkin .demo-image-preview > img,
  #checkin .qr-code canvas {
    width: 148px !important;
    height: 148px !important;
  }
  #checkin .qr-code {
    top: 0;
    right: 0;
    width: 148px;
    height: 148px;
    transform: none;
    position: absolute;
    margin-top: 10px;
  }
  #checkin .demo-image-preview {
    text-align: left;
    position: absolute;
    height: 168px;
    overflow: hidden;
    width: 148px;
  }
  #checkin .demo-image-preview > img {
    width: 168px !important;
    height: 168px !important;
    max-width: inherit;
    margin-left: -10px;
    transform: scaleX(-1);
  }
  #checkin .demo-image-preview > img.returning-visitor-photo {
    margin-top: 10px;
    margin-left: 0;
    position: relative;
  }
  #checkin .confirm-details {
    width: 488px;
    height: 168px;
    margin-top: 0;
    transform: rotate(90deg) translate(50.2%, -85px);
    transform-origin: top center;
  }
  #checkin .confirm-details h1 {
    margin-bottom: 5px !important;
    line-height: 1.1;
  }
  #checkin .confirm-details h2 {
    display: block !important;
    font-size: 1.6rem !important;
    line-height: 1.1;
    margin-bottom: 1rem;
  }
  #checkin .confirm-details h6 {
    font-size: 1.4rem !important;
    line-height: 1.1;
    margin-bottom: 10px;
    margin-top: 1rem;
  }
  #checkin .confirm-details .department {
    display: none !important;
  }
  #checkin .confirm-details .confirm-user-details {
    margin-top: 0px;
  }
  #checkin .confirm-details > div:nth-child(1) {
    position: absolute;
    width: 488px;
    height: 148px;
  }
  #checkin .confirm-details > div:nth-child(2) {
    width: 336px;
    padding-left: 158px;
  }
  .dashboard #headers,
  .dashboard #nav,
  .dashboard h1,
  .dashboard .btn,
  .dashboard .dashboard-controls {
    display: none;
  }
}
.nowrap {
  white-space: nowrap !important;
}
.dashboard-controls .form-error {
  display: none;
}
.dashboard-border {
  height: 1px;
  background: #ccc;
}
/*# sourceMappingURL=resources/styl/main.css.map */